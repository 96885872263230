import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Layout from "../components/Layout";

export const IndexPageTemplate = ({
  title,
  featuredAlbumArt,
  foaminAlbumArtThumbnail,
  hydrothermalJazzAlbumArtThumbnail,
  terraFomaAlbumArtThumbnail,
  balladsAlbumArtThumbnail,
  doloresAfterDarkAlbumArtThumbnail,
  children,
}) => (
  <div className="container">
    <div className="row">
      <div className="col-md-6">
        <img
          className="img-fluid d-block mb-4 ratio ratio-1x1"
          src={
            !!featuredAlbumArt.childImageSharp ? featuredAlbumArt.childImageSharp.fluid.src : featuredAlbumArt
          }
          alt={title}
        />
      </div>
      <div className="col-md-6">
        <div className="d-flex align-items-center justify-content-center mb-4">
          <div className="ratio ratio-1x1">
            <iframe src="https://open.spotify.com/embed/album/3aumOi0h1aqZvM9mUc29us" title="Listen to Foamin' on Spotify" width="100%" height="380" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>
          </div>
        </div>
      </div>
    </div>
    <div className="row mb-4 mt-3">
      <div className="col">
        <h2>About</h2>
        {children}
      </div>
    </div>
    <div className="row mb-4">
      <div className="col-md-7">
        <h2>Releases</h2>
        <ol className="list-unstyled">
          <li>
            <div className="d-flex">
              <a href="https://open.spotify.com/album/3aumOi0h1aqZvM9mUc29us" target="_blank" rel="noreferrer">
                <img className="album-art-thumbnail" src={!!foaminAlbumArtThumbnail.childImageSharp ? foaminAlbumArtThumbnail.childImageSharp.fluid.src : foaminAlbumArtThumbnail} alt="Foamin' album art" />
              </a>
              <div className="coral-benders-green">
                <span className="d-block">
                  <span className="fw-bold fs-5 fst-italic text-uppercase">Foamin'</span> <small><i>EP</i></small>
                </span>
                <span className="d-block">2022</span>
              </div>
            </div>
          </li>
          <li>
            <div className="d-flex">
              <a href="https://open.spotify.com/album/5D0zYnwugEEzr1oOZ9hFWJ" target="_blank" rel="noreferrer">
                <img className="album-art-thumbnail" src={!!hydrothermalJazzAlbumArtThumbnail.childImageSharp ? hydrothermalJazzAlbumArtThumbnail.childImageSharp.fluid.src : hydrothermalJazzAlbumArtThumbnail} alt="Hydrothermal Jazz album art" />
              </a>
              <div>
                <span className="d-block">
                  <span className="fw-bold fs-5 text-uppercase">Hydrothermal Jazz</span> <small><i>LP</i></small>
                </span>
                <span className="d-block">2021</span>
              </div>
            </div>
          </li>
          <li>
            <div className="d-flex">
              <a href="https://open.spotify.com/album/1fMdHU5yGmFwfheNllUlde?si=SSjnLOZlR6SkqpMsbeK16g" target="_blank" rel="noreferrer">
                <img className="album-art-thumbnail" src={!!doloresAfterDarkAlbumArtThumbnail.childImageSharp ? doloresAfterDarkAlbumArtThumbnail.childImageSharp.fluid.src : doloresAfterDarkAlbumArtThumbnail} alt="Dolores After Dark album art" />
              </a>
              <div>
                <span className="d-block">
                  <span className="fw-bold fs-5 text-uppercase">Dolores After Dark</span> <small><i>EP</i></small>
                </span>
                <span className="d-block">2020</span>
              </div>
            </div>
          </li>
          <li>
            <div className="d-flex">
              <a href="https://open.spotify.com/album/1ddjDpGz9ZYMnBgf2C3t5v?si=Mkp2clMiRSG3BkTUAwSu8g" target="_blank" rel="noreferrer">
                <img className="album-art-thumbnail" src={!!balladsAlbumArtThumbnail.childImageSharp ? balladsAlbumArtThumbnail.childImageSharp.fluid.src : balladsAlbumArtThumbnail} alt="The Ballads of Long John Silver's album art" />
              </a>
              <div>
                <span className="d-block">
                  <span className="fw-bold fs-5 text-uppercase">The Ballads of Long John Silver's</span> <small><i>LP</i></small>
                </span>
                <span className="d-block">2019</span>
              </div>
            </div>
          </li>
          <li>
            <div className="d-flex">
              <a href="https://open.spotify.com/album/3oKh9ZwyKAm5T31iZOmF5i?si=VdPkVtMYQn-p4pWeL3tppQ" target="_blank" rel="noreferrer">
                <img className="album-art-thumbnail" src={!!terraFomaAlbumArtThumbnail.childImageSharp ? terraFomaAlbumArtThumbnail.childImageSharp.fluid.src : terraFomaAlbumArtThumbnail} alt="The Ballads of Long John Silver's album art" />
              </a>
              <div>
                <span className="d-block">
                  <span className="fw-bold fs-5 text-uppercase">Terra Foma</span> <small><i>LP</i></small>
                </span>
                <span className="d-block">2017</span>
              </div>
            </div>
          </li>
        </ol>
      </div>
      <div className="col-md-4">
        <h2>Tour</h2>
          <script charset="utf-8" src="https://widget.bandsintown.com/main.min.js"></script>
          <a class="bit-widget-initializer"
            data-artist-name="Coral Benders"
            data-font="Helvetica"
            data-language="en"
            data-display-details="false"
            data-display-track-button="false"
            data-text-color="#ffffff"
            data-background-color="#000000"
            data-separator-color="#DDDDDD"
            data-popup-background-color="#ffffff"
            data-link-color="#5fb396"
            data-link-text-color="#FFFFFF" ></a>
      </div>
    </div>
  </div>
);

IndexPageTemplate.propTypes = {
  logo: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  featuredAlbumArt: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  foaminAlbumArtThumbnail: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  hydrothermalJazzAlbumArtThumbnail: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  balladsAlbumArtThumbnail: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  terraFomaAlbumArtThumbnail: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  doloresAfterDarkAlbumArtThumbnail: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  children: PropTypes.func,
};

const IndexPage = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        title={frontmatter.title}
        featuredAlbumArt={frontmatter.featuredAlbumArt}
        foaminAlbumArtThumbnail={frontmatter.foaminAlbumArtThumbnail}
        hydrothermalJazzAlbumArtThumbnail={frontmatter.hydrothermalJazzAlbumArtThumbnail}
        terraFomaAlbumArtThumbnail={frontmatter.terraFomaAlbumArtThumbnail}
        doloresAfterDarkAlbumArtThumbnail={frontmatter.doloresAfterDarkAlbumArtThumbnail}
        balladsAlbumArtThumbnail={frontmatter.balladsAlbumArtThumbnail}
      >
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </IndexPageTemplate>
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
      html: PropTypes.string,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      html
      frontmatter {
        title
        featuredAlbumArt {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        foaminAlbumArtThumbnail {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        hydrothermalJazzAlbumArtThumbnail {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        balladsAlbumArtThumbnail {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        terraFomaAlbumArtThumbnail {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        doloresAfterDarkAlbumArtThumbnail {
          childImageSharp {
            fluid(maxWidth: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
